import React, { useEffect } from "react";
import { ConfigProvider } from "antd";
import "moment/locale/es";
import { I18nextProvider } from "react-i18next";

import MasterLayout from "../layouts/MasterLayout";
import RouteSwitch from "../components/common/routes/RouteSwitch";
import menuConfig from "../menus/menuConfig";
import Routes from "../routes";
import i18n from "../localization/i18n";
import { useSelector } from "react-redux";
import { RootState } from "@app/redux/reducers/state";
import { ConfigProviderProps } from "antd/lib/config-provider";
import enUS from 'antd/lib/locale/en_US';
import enGB from 'antd/lib/locale/en_GB';
import 'moment/locale/en-gb';
import moment from "moment";

type Locale = ConfigProviderProps['locale'];

const getLocale = (locale: string | undefined): Locale => {
  switch (locale?.toLowerCase()) {
    case 'en-gb':
      return enGB;
    default:
      return enUS;
  }
}

const RootPage = (props: any) => {
  const { userPreferences } = useSelector((state: RootState) => state.userManagement);

  useEffect(() => {
    moment.locale(userPreferences?.localizationCultureCode ?? 'en');
  }, [userPreferences?.localizationCultureCode]);
  const onChangeLanguage = (key: any) => {
    // localStorage.setItem("locale", key.key);
    // SetLocale(key.key === "en" ? enUS : es_ES);
    // i18n.changeLanguage(key.key);
  };

  return (
    <ConfigProvider locale={getLocale(userPreferences?.localizationCultureCode)}>
      <I18nextProvider i18n={i18n}>
        <MasterLayout
          onChangeLanguage={onChangeLanguage}
          {...props}
          routes={Routes}
          menus={menuConfig}
        >
          <RouteSwitch routes={props.routes} />
        </MasterLayout>
      </I18nextProvider>
    </ConfigProvider>
  );
};

export default RootPage;
