import React from "react";
import Tooltip from "antd/lib/tooltip";
import { useSelector } from "react-redux";
import { RootState } from "@app/redux/reducers/state";

const useAllowedDomain = (): boolean => {
  const { user } = useSelector((rootState: RootState) => rootState.auth);
  const userName = user?.preferred_username?.toLowerCase() || '';
  const w = window as any;

  const allowedDomains =
    process.env.ALLOWED_EMAIL_DOMAINS ||
    (w._env_ && w._env_.ALLOWED_EMAIL_DOMAINS) ||
    '@iris.co.uk,@irisglobal.com';

  const loggedUserDomain = userName.split('@').pop();

  return loggedUserDomain
    ? allowedDomains.split(',').includes(`@${loggedUserDomain}`)
    : false;
};

const useDynamicYearRange = (): string => {
  const startYear = 2023;
  const currentYearLastDigit = new Date().getFullYear() % 10;
  return `${startYear}-${currentYearLastDigit}`;
};

const AuthTooltip: React.FC = () => {
  const { tenant } = useSelector((rootState: RootState) => rootState.auth);
  const isAllowedDomain = useAllowedDomain();
  const yearRange = useDynamicYearRange();

  const version =
    (window as any)._env_?.REACT_APP_VERSION || process.env.REACT_APP_VERSION;

  return (
    <Tooltip
      placement='top'
      title={
        isAllowedDomain && (
          <span style={{ marginRight: '3px' }}>Version: {version}</span>
        )
      }
    >
      <span>Copyright © IRIS Software Group {yearRange} </span>
      <span>Tenant: {tenant}</span>
    </Tooltip>
  );
};

export default AuthTooltip;
