import axios from "axios";
import HTTPRequestConfig from "./interfaces/RequestConfig";
import httpVerbsExport from "./httpVerbs";
import Constants from "../../constants";
import { store } from "../../../";
const w = window as any;

export const ERROR_TYPE_CANCEL = "ERR_CANCELED";

const setHeaders = (customHeaders: any) => {
  const client = axios.create({ baseURL: Constants.baseUrlPlaceholder });

  const access_token = JSON.parse(
    localStorage.getItem(Constants.tokenKey) ?? ""
  )?.accessToken;

  //common headers
  client.defaults.headers.common[
    "Authorization"
  ] = `Bearer ${access_token?.accessToken}`;
  client.defaults.headers.common["Content-Type"] = "application/json";
  client.defaults.headers.common["Cache-Control"] = "no-cache";
  client.defaults.headers.common["Pragma"] = "no-cache";
  client.defaults.headers.common["Expires"] = "0";

  //custom header override
  // eg :- {authorization:"abcd123",Content-Type:"something"}
  for (let header in customHeaders) {
    switch (header) {
      case "authorization":
        client.defaults.headers.common[
          "Authorization"
        ] = `Bearer ${access_token?.accessToken}`;
        break;
    }
  }

  return client;
};

const changeUrl = (req: HTTPRequestConfig) => {
  let path: string;
  try {
    path = new URL(req.url ?? "").pathname.slice(1);
    req.url = path;
  } catch (error) {
    path = req.url ?? "";
  }
  const state = store.getState();
  const { endPoints, tenant } = state.auth;

  let key = path.slice(0, path.indexOf("/api"));

  if (key) {
    let baseUrl = endPoints?.find((e) => {
      console.info('e.serviceType', e.serviceType?.toString() );
      return e.serviceType?.toString() == key
    });
    if (baseUrl) {
      req.baseURL = baseUrl.entrypointAddress + "/" + tenant;
      req.url = path.slice(path.indexOf("/"));
    }
  }
  const backendEnv =
    process.env.REACT_APP_BACKEND_ENV ||
    (w._env_ && w._env_.REACT_APP_BACKEND_ENV);
  if (backendEnv) {
    req.baseURL = req.baseURL?.replace(".dev", "." + backendEnv);
  }
};

const axiosApiClient = (options: HTTPRequestConfig, customHeaders?: any) => {
  const client = setHeaders(customHeaders ? customHeaders : {});

  changeUrl(options);

  const onSuccess = (data: any): any => {
    return data;
  };
  const onError = (error: any): any => {
    console.error("Request Failed:", error.config);
    if (error.response) {
      console.error("Status:", error.response.status);
      console.error("Data:", error.response.data);
      console.error("Headers:", error.response.headers);
    } else {
      console.error("Error Message:", error.message);
    }
    throw (!(error?.response?.data instanceof Blob) && error?.response?.data)? error.response.data : error;
  };

  return client(options).then(onSuccess).catch(onError);
};

export const httpVerbs = httpVerbsExport;
export default axiosApiClient;
