import 'rc-tree/assets/index.css';
import React, { useEffect, useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import ReactTooltip from 'react-tooltip';
import Tree from 'rc-tree';
import { Button, Checkbox, Col, Input, InputNumber, Modal, Row, Tooltip, Typography } from 'antd';
import { CheckOutlined, CloseOutlined, DeleteOutlined, EditOutlined, FolderFilled, MinusSquareFilled, PlusOutlined, PlusSquareFilled } from '@ant-design/icons';
import { DataNode, EventDataNode } from 'antd/lib/tree';
import { MdDeleteSweep } from 'react-icons/md';
import _ from 'lodash';

import './foldertree.css';
import styles from './index.module.less';
import useStateRef from '@app/hooks/useStateRef';
import { errorNotification } from '@app/utils/antNotifications';
import { prevenPrecedingSpaces, prevenPrecedingSpacesOnPaste } from '@app/utils/forms';
import { useSelector } from "react-redux";
import { RootState } from "@app/redux/reducers/state";

const NEW_FOLDER_LABEL = 'New Folder';
const TOOLTIP_ADD_NEW_FOLDER = 'Create Sub Folder';
const TOOLTIP_RENAME_FOLDER = 'Rename Folder';
const TOOLTIP_DELETE_FOLDER = 'Delete Folder';
const LABEL_ADD_PARENT_LABEL = 'ADD FOLDER';
const TOOLTIP_DELETE_CHILD_FOLDERS = 'Delete All Child Folders';
const FOLDER_COLOR_BLUE = '#134A82';
const FOLDER_COLOR_YELLOW = '#24303B';
const BORDER_COLOR_RED = '#C8102E';
export const MIN_RETENTION_VALUE = 1;
export const MAX_RETENTION_VALUE = 20;

export interface TreeDataNode extends DataNode {
  parent?: boolean;
  children?: TreeDataNode[];
  retention?: number;
  folderName?: string;
  checked?: boolean;
  preSaved?: boolean;
  indeterminate?: boolean;
}

interface TreeEventDataNode extends EventDataNode {
  parent: boolean;
}

export interface FolderTreeProps {
  data?: any;
  onChange?: () => void;
  onFolderTreeUnmount?: (treeData: TreeDataNode[]) => void;
  onDupicateFolderAdded?: (duplicateName: boolean) => void;
  onEmptyFolderAdded?: (duplicateFolder: boolean) => void;
}

type renderMode = 'parent' | 'child' | 'addParent' | 'addChild';

export const orderByAlphaNumericTitle = (data: any, key = 'folderName', order = 'asc') => {
  if (data) {
    const returnData = data.slice().sort((firstValue: any, secondValue: any) =>
      firstValue[key].localeCompare(secondValue[key], undefined, {
        numeric: true,
      })
    );
    return order === 'asc' ? returnData : returnData.reverse();
  } else {
    return [];
  }
};

export default ({ data, onChange, onFolderTreeUnmount, onDupicateFolderAdded, onEmptyFolderAdded }: FolderTreeProps) => {
  let cmContainer: any;
  let tooltip: any;
  const [showRetentionModel, setShowRetentionModel] = useState(false);
  const [treeData, setTreeData] = useState<Array<TreeDataNode>>([
    {
      key: '0',
      folderName: NEW_FOLDER_LABEL,
      selectable: false,
      children: [
        {
          key: '0_0',
          folderName: NEW_FOLDER_LABEL,
          retention: 0,
          selectable: false,
          icon: <FolderFilled style={{ color: FOLDER_COLOR_BLUE }} />,
          isLeaf: true,
        },
        {
          key: '0_1',
          folderName: `${NEW_FOLDER_LABEL} (2)`,
          retention: 0,
          selectable: false,
          icon: <FolderFilled style={{ color: FOLDER_COLOR_BLUE }} />,
          isLeaf: true,
        },
      ],
      parent: true,
    },
  ]);
  const [retention, setRetention] = useState<number>(0);
  const [checkedKeys, setCheckedKeys] = useState<any[]>([]);
  const [hasPreSavedForChecked, setHasPreSavedForChecked] = useState<boolean>(false);
  const { userPermission } = useSelector( (state: RootState) => state.userManagement );

  const uncheckAll = (data: TreeDataNode[]) => {
    data.forEach((node: TreeDataNode) => {
      node.checked = false;
      if (node.children) {
        uncheckAll(node.children);
      }
    });
    setTreeData([...data]);
  };

  const onCheck = () => {
    onChange && onChange();
    let preSavedChecked = false;
    const getPresaved = (node: TreeDataNode[]) => {
      node.forEach((node: TreeDataNode) => {
        if (node.checked && node.preSaved) {
          preSavedChecked = true;
          return;
        }
        if (node.children) {
          getPresaved(node.children);
        }
      });
    };
    getPresaved(treeData);
    setHasPreSavedForChecked(preSavedChecked);
  };
  const onExpand = (event: any, info: any) => {
    onChange && onChange();
    setAutoExpandedParent(false);
    setExpandedKeys([...event]);
  };
  const inputRef = useRef() as any;
  const buttonRef = useRef() as any;
  const [expandedKeys, setExpandedKeys] = useState<Array<any>>(['0', '0_0', '0_1']);
  const [autoExpandedParent, setAutoExpandedParent] = useState(true);
  const [currentFolderKey, setCurrentFolderKey, folderKeyRef] = useStateRef('');
  const [displayNameError, setDisplayNameError, displayErrorRef] = useStateRef(false);
  const [displayEmptyError, setDisplayEmptyError, displayEmptyErrorRef] = useStateRef(false);

  useEffect(() => {
    return () => {
      onFolderTreeUnmount && onFolderTreeUnmount(treeData);
    };
  }, [currentFolderKey, displayNameError, displayEmptyError]);

  const renderSubFolders = (subFolders: Array<any>, parentFolderIndex: number) => {
    return subFolders.map((subNode: any, index: number) => {
      return {
        id:subNode.id,
        key: `${parentFolderIndex}_${subNode.id}`,
        folderName: subNode.name,
        retention: Number(subNode.retention),
        selectable: false,
        icon: <FolderFilled style={{ color: FOLDER_COLOR_BLUE }} />,
        isLeaf: true,
        preSaved: subNode.presist ?? true,
      };
    });
  };

  const handleDataToTree = (tree: any) => {
    return tree.map((node: any, index: number) => {
      return {
        id: node.id,
        key: `${node.id}`,
        folderName: node.name,
        selectable: false,
        icon: <FolderFilled style={{ color: FOLDER_COLOR_YELLOW }} />,
        children: renderSubFolders(node.subFolders, node.id),
        parent: true,
        preSaved: node.presist ?? true,
      };
    });
  };

  useEffect(() => {
    if (data) {
      setTreeData([...handleDataToTree(data)]);
      setExpandedKeys([...data.map((node: any) => `${node.id}`)]);
      setAutoExpandedParent(true);
    }
  }, [data]);

  const getIcon = (e: TreeDataNode, color: string) => {
    const onChangeChecked = (ev: any) => {
      e.checked = ev.target.checked;
      if (e.children) {
        e.children.forEach((child: TreeDataNode) => {
          child.checked = ev.target.checked;
        });
      }
      checkParentCheckable();
      setTreeData([...treeData]);
      onCheck();
    };
    return (
      <Row justify="start">
        <Col>
          <Checkbox checked={e.checked} indeterminate={e.checked ? undefined : e.indeterminate} onChange={onChangeChecked} />
        </Col>
        <Col>
          <FolderFilled style={{ color: color }} />
        </Col>
      </Row>
    );
  };

  const checkParentCheckable = () => {
    treeData.forEach((node: TreeDataNode) => {
      if (!node.children?.length) return;
      let allChecked = true;
      let indeterminated = false;
      node.children?.forEach((child: TreeDataNode) => {
        if (!child.checked) allChecked = false;
        else indeterminated = true;
      });
      if (allChecked) node.checked = true;
      else node.checked = false;
      if (indeterminated) node.indeterminate = true;
      else node.indeterminate = false;
    });
  };

  const displayEditTreeNodeError = () => {
    if (retention === 0) {
      return;
    }
    if (!retention)
      return (
        <Col className="yj-tree-node-error-col" span={24}>
          <div className="yj-tree-node-error">Required Field</div>
        </Col>
      );
    if (retention < MIN_RETENTION_VALUE)
      return (
        <Col className="yj-tree-node-error-col" span={24}>
          <div className="yj-tree-node-error">Value cannot be less than {MIN_RETENTION_VALUE}</div>
        </Col>
      );
    if (retention > MAX_RETENTION_VALUE)
      return (
        <Col className="yj-tree-node-error-col" span={24}>
          <div className="yj-tree-node-error">Value cannot be more than {MAX_RETENTION_VALUE}</div>
        </Col>
      );
  };

  const displayTreeNodeError = (data: TreeDataNode) => {
    if (data.retention === 0) {
      return;
    }
    if (!data.retention)
      return (
        <Col className="yj-tree-node-error-col" offset={16} span={8}>
          <div className="yj-tree-node-error">Required Field</div>
        </Col>
      );
    if (data.retention < MIN_RETENTION_VALUE)
      return (
        <Col className="yj-tree-node-error-col" offset={16} span={8}>
          <div className="yj-tree-node-error">Value cannot be less than {MIN_RETENTION_VALUE}</div>
        </Col>
      );
    if (data.retention > MAX_RETENTION_VALUE)
      return (
        <Col className="yj-tree-node-error-col" offset={16} span={8}>
          <div className="yj-tree-node-error">Value cannot be more than {MAX_RETENTION_VALUE}</div>
        </Col>
      );
  };

  useEffect(() => {
    onFolderTreeUnmount && onFolderTreeUnmount(treeData);
    checkParentCheckable();
    const getCheckedKeys = (data: TreeDataNode[]) => {
      const checkedKeys: any[] = [];
      data.forEach((node: TreeDataNode) => {
        if (node.checked) {
          checkedKeys.push(node.key);
        }
        if (node.children?.length) {
          checkedKeys.push(...getCheckedKeys(node.children));
        }
      });
      return checkedKeys;
    };

    setCheckedKeys(getCheckedKeys(treeData));

    treeData.forEach((treeNode) => {
      treeNode.icon = getIcon(treeNode, FOLDER_COLOR_YELLOW);
      treeNode.title = (
        <Row 
          justify="space-between" 
          align="middle" 
          data-node-key={treeNode.key}
          style={{width: '550px'}}>
          <Col className={styles.YJTreeNodeFolderName}>
            <Tooltip placement="leftTop" title={treeNode.folderName}>
              {treeNode.folderName}
            </Tooltip>
          </Col>
          <Col>
            {userPermission.privDMSCanManageTemplates && (
              <>
              <Button 
                type="text" 
                data-tip
                data-for="renameFolder"
                icon={<EditOutlined />} 
                onClick={(event) => {
                  event.stopPropagation();
                  EditParentFolder(treeNode, undefined, false, true, false);
                }}
                data-node-key={treeNode.key}
              />
              <ReactTooltip backgroundColor={FOLDER_COLOR_BLUE} id="renameFolder">
                <span>{TOOLTIP_RENAME_FOLDER}</span>
              </ReactTooltip>
              </>
            )}
            {!treeNode.preSaved && (
              <Button 
                type="text" 
                icon={<DeleteOutlined />} 
                onClick={(event) => {
                  event.stopPropagation();
                  const nodeKey = treeNode.key;
                  if (nodeKey !== undefined) {
                    removeNodes([nodeKey]);
                  }
                }}
                data-node-key={treeNode.key}
              />
            )}
              {userPermission.privDMSCanManageTemplates && treeNode.parent && (
                <>
                <Button
                type="text"
                data-tip
                data-for="createSubFOlder"
                icon={<PlusOutlined />}
                onClick={(event) => {
                  event.stopPropagation();
                  AddNewSubFolder(treeNode, false);
                }}
                data-node-key={treeNode.key} />
              <ReactTooltip backgroundColor={FOLDER_COLOR_BLUE} id="createSubFOlder">
                <span>{TOOLTIP_ADD_NEW_FOLDER}</span>
              </ReactTooltip>
                </>
              )}
          </Col>
        </Row>
      );

      treeNode.children?.forEach((child) => {
        child.icon = (
          <div className={styles.YJTreeNodeChildIcon}>
            {getIcon(child, FOLDER_COLOR_BLUE)}
          </div>
        )
        child.title = (
          <>
          <Row 
            justify="space-between" 
            align="middle" 
            data-node-key={treeNode.key}
            style={{width: child.preSaved ? '500px' : '530px'}}>
            <Col className={styles.YJTreeNodeFolderName}>
            <Tooltip placement="leftTop" title={child.folderName}>
              {child.folderName}
            </Tooltip>
            </Col>
            <Col>
            {userPermission.privDMSCanManageTemplates && (
              <Button
                  type="text"
                  data-tip
                  data-for="renameFolder"
                  icon={<EditOutlined />}
                  onClick={(event) => {
                    event.stopPropagation();
                    EditLeafFolder(child, undefined, false, true);
                  }}
                  data-node-key={treeNode.key} />
            )}
              {!child.preSaved && (
                <>
                <Button
                    type="text"
                    data-tip
                    data-for="deleteFolder"
                    icon={<DeleteOutlined />}
                    onClick={(event) => {
                      event.stopPropagation();
                      const nodeKey = child.key;
                      if (nodeKey !== undefined) {
                        removeNodes([nodeKey]);
                      }
                    }}
                    data-node-key={treeNode.key} />
                    <ReactTooltip backgroundColor={FOLDER_COLOR_BLUE} id="deleteFolder">
                      <span>{TOOLTIP_DELETE_FOLDER}</span>
                    </ReactTooltip>
                    </>
              )}
              {child.children?.some((n) => !n.preSaved) && (
                <>
                <Button
                  type="text"
                  data-tip
                  data-for="deleteChildFolders"
                  icon={<MdDeleteSweep />}
                  onClick={(event) => {
                    event.stopPropagation();
                    const childKeys = child.children
                      ?.filter((node) => !node.preSaved)
                      .map((n) => n.key)
                      .filter((key): key is string | number => key !== undefined);
                    if (childKeys && childKeys.length > 0) {
                      removeNodes(childKeys);
                    }
                  }}
                  data-node-key={treeNode.key} />
                  <ReactTooltip backgroundColor={FOLDER_COLOR_BLUE} id="deleteChildFolders">
                  <span>{TOOLTIP_DELETE_CHILD_FOLDERS}</span>
                </ReactTooltip>
                </>
              )}
            </Col>
          </Row>
          <Row justify="start">
              <Col>
                <Checkbox
                  onChange={(ev) => {
                    child.retention = ev.target.checked ? 0 : 1;
                    setTreeData([...treeData]);
                  }}
                  checked={child.retention == 0}
                >
                  Permanent
                </Checkbox>
              </Col>
              <Col>
                <div>
                  <InputNumber
                    value={child.retention == 0 ? "-" : child.retention}
                    defaultValue={child.retention == 0 ? "-" : 1}
                    size="small"
                    disabled={child.retention == 0}
                    onChange={(ev) => {
                      (typeof ev == "number" || ev == null) && (child.retention = ev);
                      setTreeData([...treeData]);
                    }} />
                  <span className="lbl-years">Year(s)</span>
                </div>
              </Col>
              {displayTreeNodeError(child)}
            </Row>
            </>
        );
        child.className = 'YJ-folder-tree-node';
      });
    });
  }, [treeData]);

  const updateExpandedKeys = (key: string) => {
    const exsistingKeys = expandedKeys;
    exsistingKeys.push(key);
    setExpandedKeys([...exsistingKeys]);
    setAutoExpandedParent(true);
  };

  const handleFolderAction = (renderMode: renderMode, cancelled: boolean, edited: boolean, discarded = false, onBlur = false, node:TreeDataNode) => {
    uncheckAll(treeData);
    if (displayEmptyErrorRef?.current) {
      errorNotification([''], 'Folder name cannot be empty');
      onEmptyFolderAdded && onEmptyFolderAdded(true);
      return;
    }
    onEmptyFolderAdded && onEmptyFolderAdded(false);
    if (onChange) {
      onChange();
    }
    renderMode === 'child'
      ? EditLeafFolder(node, inputRef.current?.state.value, cancelled, edited)
      : renderMode === 'parent'
      ? EditParentFolder(node, inputRef.current?.state.value, cancelled, edited, discarded)
      : renderMode === 'addParent'
      ? AddNewParentFolder(node, true, inputRef.current?.state.value)
      : AddNewSubFolder(node, true, inputRef.current?.state.value);
  };

  const getFoldername = (folderList: TreeDataNode[], count: number) => {
    const expression = '^New Folder \\([0-9]\\d*\\)$';
    const matched = folderList.filter((value: any) => value.folderName?.toString().trim().match(expression) || value.folderName?.toString().trim() === NEW_FOLDER_LABEL);
    const sortedMatchedFolders = orderByAlphaNumericTitle(matched, 'folderName', 'desc').filter((node: TreeDataNode) => node.folderName !== NEW_FOLDER_LABEL);
    const highestValue = sortedMatchedFolders.length > 0 ? Number(sortedMatchedFolders[0]?.folderName?.toString().split('(')[1].split(')')[0]) : 1;
    return folderList.length > 0 ? `${NEW_FOLDER_LABEL} (${highestValue + 1})` : NEW_FOLDER_LABEL;
  };

  const checkFolderName = (folderList: TreeDataNode[], title: string) => {
    if (
      folderList.filter(
        (folder) =>
          folder.folderName
            ?.toString()
            .replace(/ +(?= )/g, '')
            .toLowerCase()
            .trim() ===
          title
            .replace(/ +(?= )/g, '')
            .trim()
            .toLowerCase()
      ).length > 0
    ) {
      setDisplayNameError(true);
      return true;
    } else {
      setDisplayNameError(false);
      return false;
    }
  };

  const displayDuplicateNameError = () => {
    errorNotification([''], 'Folder Name already exists in the same level');
    if (inputRef.current) {
      inputRef.current.input.style.borderColor = BORDER_COLOR_RED;
    }
    uncheckAll(treeData);
  };

  const generateFolderNode = (key: string | number, icon: { fontSize: number; color: string; default: boolean }, title: any, isLeaf = false, parent = false): TreeDataNode => {
    return {
      key: key,
      folderName: title,
      selectable: false,
      icon: icon.default ? undefined : <FolderFilled style={{ fontSize: icon.fontSize, color: icon.color }} />,
      isLeaf: isLeaf,
      parent: parent,
      retention: 0,
    };
  };

  const saveParentFolder = (folderList: TreeDataNode[], key: string | number, icon: { fontSize: number; color: string; default: boolean }, title: any) => {
    if (checkFolderName(folderList, title)) {
      displayDuplicateNameError();
      onDupicateFolderAdded && onDupicateFolderAdded(true);
    } else {
      onDupicateFolderAdded && onDupicateFolderAdded(false);
      folderList.push(generateFolderNode(key, icon, title, true, true));
      setTreeData([...orderByAlphaNumericTitle(folderList)]);
      setCurrentFolderKey(undefined);
    }
  };

  const saveEditableParentFolder = (folderList: TreeDataNode[], key: string | number, icon: { fontSize: number; color: string; default: boolean }, title: any) => {
    if (!displayErrorRef.current) {
      setCurrentFolderKey(key);
      const orderedFolders = orderByAlphaNumericTitle(folderList);
      orderedFolders.unshift(generateFolderNode(key, icon, title, true, true));
      setTreeData([...orderedFolders]);
    }
  };

  const AddNewParentFolder = (node?: TreeDataNode, saved = false, title?: any) => {
    onChange && onChange();
    const folderTree = treeData;
    const keys = orderByAlphaNumericTitle(folderTree, 'key', 'desc');
    const previousKey = keys.length > 0 ? keys[0].key : 0;
    const folderCounter = folderTree.filter((node: TreeDataNode) => {
      return node.folderName?.toString().trim().includes(NEW_FOLDER_LABEL);
    });
    const newFolders = [...folderTree];
    const key = previousKey === 0 ? '1' : (Number(previousKey) + 1).toString();
    updateExpandedKeys(key);
    if (saved) {
      const filtredNewFolders = folderTree.filter((folder) => folder.key !== folderKeyRef.current);
      saveParentFolder(filtredNewFolders, folderKeyRef.current ? folderKeyRef.current : '', { color: FOLDER_COLOR_YELLOW, fontSize: 15, default: false }, title);
    } else {
      saveEditableParentFolder(
        newFolders,
        previousKey === 0 ? '1' : (Number(previousKey) + 1).toString(),
        { color: FOLDER_COLOR_YELLOW, fontSize: 15, default: false },
        renderEditableTitle(folderCounter.length <= 0 ? NEW_FOLDER_LABEL : getFoldername(treeData, treeData.length + 1), 'addParent', node!)
      );
    }
  };

  const saveSubFolder = (
    folderList: TreeDataNode[],
    filteredNodes: TreeDataNode[],
    updatedChildren: TreeDataNode[],
    key: string | number,
    icon: { fontSize: number; color: string; default: boolean },
    title: any,
    hoveredObjectInput: TreeDataNode | undefined
  ) => {
    if (checkFolderName(folderList, title)) {
      displayDuplicateNameError();
      onDupicateFolderAdded && onDupicateFolderAdded(true);
    } else {
      onDupicateFolderAdded && onDupicateFolderAdded(false);
      folderList.push(generateFolderNode(key, icon, title, true, false));
      const ordredOjects = orderByAlphaNumericTitle(folderList);
      const newRootObject = {
        ...hoveredObjectInput,
        icon: undefined,
        children: updatedChildren ? [...ordredOjects] : ([] as TreeDataNode[]),
        isLeaf: false,
      } as TreeDataNode;
      filteredNodes.push(newRootObject);
      setCurrentFolderKey(undefined);
      setTreeData([...orderByAlphaNumericTitle(filteredNodes)]);
    }
  };

  const saveEditableSubFOlder = (
    updatedChildren: TreeDataNode[],
    key: string | number,
    icon: { fontSize: number; color: string; default: boolean },
    title: any,
    hoveredObjectInput: TreeDataNode | undefined
  ) => {
    if (!displayErrorRef.current) {
      setCurrentFolderKey(key);
      updatedChildren?.unshift(generateFolderNode(key, icon, title, true, false));
      const newRootObject = {
        ...hoveredObjectInput,
        children: updatedChildren ? [...updatedChildren] : ([] as TreeDataNode[]),
      } as TreeDataNode;
      const folderList = treeData;
      const newRootIndex = folderList.findIndex((folder) => folder.key === hoveredObjectInput?.key);
      folderList[newRootIndex] = newRootObject;
      setTreeData([...folderList]);
    }
  };

  const AddNewSubFolder = (node: TreeDataNode, saved = false, title?: any) => {
    onChange && onChange();
    const hoveredObject = treeData.find((folder) => folder.key === node?.key);
    const filteredObjects = treeData.filter((folder) => folder.key !== node?.key);
    const newChildren = hoveredObject?.children ? (hoveredObject.children as TreeDataNode[]) : ([] as TreeDataNode[]);
    const keys = orderByAlphaNumericTitle(newChildren, 'key', 'desc');
    const folderKey = keys && keys.length > 0 ? `${node?.key}_${Number(keys[0].key.toString().split('_')[1]) + 1}` : `${node?.key}_0`;
    updateExpandedKeys(folderKey);
    if (saved) {
      const filteredNewChildren = newChildren.filter((folder) => folder.key !== folderKeyRef.current);
      saveSubFolder(filteredNewChildren, filteredObjects, newChildren, folderKeyRef.current, { color: FOLDER_COLOR_BLUE, fontSize: 15, default: false }, title, hoveredObject);
    } else {
      saveEditableSubFOlder(
        newChildren,
        folderKey,
        { color: FOLDER_COLOR_BLUE, fontSize: 15, default: false },
        renderEditableTitle(keys && keys.length > 0 ? getFoldername(newChildren, newChildren.length) : NEW_FOLDER_LABEL, 'addChild', node),
        hoveredObject
      );
    }
  };

  const EditParentFolder = (node: TreeDataNode, title?: any, cancelled = false, editNode = false, discarded = false) => {
    onChange && onChange();
    const parentKey = discarded ? folderKeyRef.current : node?.key;
    const parentObject = treeData.find((node) => node.key === parentKey) as TreeDataNode;
    const parentIndex = treeData.findIndex((node) => node.key === parentKey);
    const filteredParentObjects = treeData.filter((node) => node.key !== parentKey);
    let updatedParentObject;

    if (cancelled) {
      updatedParentObject = {
        ...parentObject,
        folderName: inputRef.current.props.defaultValue,
      } as TreeDataNode;
    } else {
      updatedParentObject = {
        ...parentObject,
        folderName: title ? title : renderEditableTitle(parentObject?.folderName?.toString(), 'parent', node),
      } as TreeDataNode;
    }

    if (editNode) {
      treeData[parentIndex] = updatedParentObject;
      setTreeData([...treeData]);
    } else {
      if (!cancelled && checkFolderName(filteredParentObjects, title)) {
        displayDuplicateNameError();
        onDupicateFolderAdded && onDupicateFolderAdded(true);
      } else {
        onDupicateFolderAdded && onDupicateFolderAdded(false);
        filteredParentObjects.push(updatedParentObject);
        setTreeData([...orderByAlphaNumericTitle(filteredParentObjects)]);
      }
    }
  };

  const EditLeafFolder = (childNode: TreeDataNode, title?: any, cancelled = false, editNode = false) => {
    onChange && onChange();
    const parentKey = childNode?.key.toString().split('_')[0];
    const parentObject = treeData.find((node) => node.key === parentKey) as TreeDataNode;
    const parentIndex = treeData.findIndex((node) => node.key === parentKey);
    const filteredParentObjects = treeData.filter((node) => node.key !== parentKey);
    const childObject = parentObject.children?.find((node) => node.key === childNode?.key);
    const childObjectIndex = parentObject.children?.findIndex((node) => node.key === childNode?.key) as number;
    const filtredChildObjects = parentObject.children?.filter((node) => node.key !== childNode?.key) as TreeDataNode[];

    let updatedChildObject;
    if (cancelled) {
      updatedChildObject = {
        ...childObject,
        folderName: inputRef.current.props.defaultValue,
      } as TreeDataNode;
    } else {
      updatedChildObject = {
        ...childObject,
        folderName: title ? title : renderEditableTitle(childObject?.folderName?.toString(), 'child', childNode),
      } as TreeDataNode;
    }

    if (editNode && parentObject.children) {
      parentObject.children[childObjectIndex] = updatedChildObject;
      treeData[parentIndex] = parentObject;
      setTreeData([...treeData]);
    } else {
      if (!cancelled && filtredChildObjects && checkFolderName(filtredChildObjects, title)) {
        displayDuplicateNameError();
        onDupicateFolderAdded && onDupicateFolderAdded(true);
      } else {
        onDupicateFolderAdded && onDupicateFolderAdded(false);
        filtredChildObjects?.push(updatedChildObject);
        parentObject.children = orderByAlphaNumericTitle(filtredChildObjects);
        filteredParentObjects.push(parentObject);
        setTreeData([...orderByAlphaNumericTitle(filteredParentObjects)]);
      }
    }
  };

  const handleOnFoucsInput = (event: any) => {
    event.target.select();
  };

  const handleOnChangeInput = (event: any) => {
    if (event.target.value.length <= 0) {
      setDisplayEmptyError(true);
      inputRef.current.input.style.borderColor = BORDER_COLOR_RED;
      buttonRef.current.disabled = true;
      errorNotification([''], 'Folder name cannot be empty');
    } else {
      buttonRef.current.disabled = false;
      inputRef.current.input.style.borderColor = '';
      setDisplayEmptyError(false);
    }
  };

  const removeNodesFromTree = (tree: TreeDataNode[], keys: any[]) => {
    let deleteIndex: number[] = [];
    tree.forEach((node) => {
      if (keys.includes(node.key)) {
        deleteIndex.push(tree.indexOf(node));
      } else {
        if (node.children) removeNodesFromTree(node.children, keys);
      }
    });
    deleteIndex.forEach((index) => {
      tree.splice(index, 1);
    });
    return tree;
  };

  const removeNodes = (keys: any[] | undefined) => {
    if (!keys || keys.length === 0) return;
    
    onChange && onChange();
    const newTree = removeNodesFromTree(treeData, keys);
    setDisplayNameError(false);
    uncheckAll(treeData);
    setTreeData([...orderByAlphaNumericTitle(newTree)]);
  };

  const cancelOnMouseDown = (event: any, renderMode: renderMode, node: TreeDataNode) => {
    event.stopPropagation();
    event.preventDefault();
    setDisplayNameError(false);
    setDisplayEmptyError(false);
    renderMode === 'child' ? EditLeafFolder(node, undefined, true) : renderMode === 'parent' ? EditParentFolder(node, undefined, true) : removeNodes([folderKeyRef.current?.toString()]);
  };

  const saveOnMouseDown = (event: any, renderMode: renderMode, node: TreeDataNode) => {
    event.stopPropagation();
    event.preventDefault();
    handleFolderAction(renderMode, false, false, false, false, node);
  };

  const renderEditableTitle = (defaultValue: any, renderMode: renderMode, node: TreeDataNode) => {
    return (
      <>
        <div>
          <Input
            onInput={(event) => prevenPrecedingSpacesOnPaste(event)}
            onKeyDown={(event) => prevenPrecedingSpaces(event)}
            maxLength={50}
            onBlur={(e) => {
              handleFolderAction(renderMode, false, false, false, false, node);
            }}
            autoFocus
            onFocus={handleOnFoucsInput}
            id="folderNameInput"
            onChange={handleOnChangeInput}
            ref={inputRef}
            onClick={(e) => {
              e.stopPropagation();
            }}
            defaultValue={defaultValue}
            style={{ maxWidth: '250px' }}
          />
          <span>
            <Button
              ref={buttonRef}
              type="default"
              id="saveFolderName"
              onMouseDown={(event) => saveOnMouseDown(event, renderMode, node)}
            >
              <CheckOutlined />
            </Button>
          </span>
          <Button type="default" onMouseDown={(event) => cancelOnMouseDown(event, renderMode, node)}>
            <CloseOutlined />
          </Button>
        </div>
      </>
    );
  };

  const onClickDelete = (event: any) => {
    event.stopPropagation();
    removeNodes(checkedKeys);
    uncheckAll(treeData);
  };

  const onEditDone = () => {
    treeData.forEach((node: TreeDataNode) => {
      node.children?.forEach((child: TreeDataNode) => {
        if (checkedKeys.includes(child.key)) child.retention = retention;
      });
    });
    setTreeData([...treeData]);
    setShowRetentionModel(false);
    setRetention(0);
    uncheckAll(treeData);
  };

  useEffect(() => {
    const replaceNodeWithReactComponent = (element: Element, reactComponent: any) => {
      ReactDOM.render(reactComponent, element);
    };

    let switcher = document.getElementsByClassName('rc-tree-switcher_open');
    if (switcher) {
      for (let i = 0; i < switcher.length; i++) {
        replaceNodeWithReactComponent(switcher[i], <MinusSquareFilled className="YJ-FolderTreeNode-Expand" />);
      }
    }

    switcher = document.getElementsByClassName('rc-tree-switcher_close');
    if (switcher) {
      for (let i = 0; i < switcher.length; i++) {
        replaceNodeWithReactComponent(switcher[i], <PlusSquareFilled className="YJ-FolderTreeNode-Expand" />);
      }
    }
  });

  return (
    <div className={styles.yjFolderStructureSection}>
      <Modal
        destroyOnClose={true}
        key="100"
        visible={showRetentionModel}
        title={'RETENTION SETTINGS'}
        onCancel={() => setShowRetentionModel(false)}
        footer={[
          <Button key="back" type="default" onClick={() => setShowRetentionModel(false)}>
            cancel
          </Button>,

          <Button key="checkin" type="primary" onClick={onEditDone}>
            done
          </Button>,
        ]}
      >
        <div className={styles.yjModalContentWrapper}>
          <Typography.Title level={5} className="YJ-Retention-Edit-Title">
            Retention
          </Typography.Title>
          <Row justify="start">
            <Col>
              <Checkbox
                onChange={(ev) => {
                  setRetention(ev.target.checked ? 0 : 1);
                }}
                checked={retention == 0}
              >
                Permanent
              </Checkbox>
            </Col>
            <Col>
              <div>
                <InputNumber
                  value={retention == 0 ? '-' : retention}
                  defaultValue={retention == 0 ? '-' : 1}
                  size="small"
                  disabled={retention == 0}
                  onChange={(ev) => {
                    (typeof ev == 'number' || ev == null) && setRetention(ev);
                  }}
                />
                &nbsp;Year(s)
              </div>
            </Col>
            {displayEditTreeNodeError()}
          </Row>
        </div>
      </Modal>
      <div className={styles.yjAddParentFolderButtonWrapper}>
        <Button
          disabled={displayEmptyErrorRef.current || !userPermission.privDMSCanManageTemplates}
          hidden={checkedKeys.length <= 0}
          type="default"
          icon={<EditOutlined />}
          onClick={() => {
            setRetention(0);
            setShowRetentionModel(true);
          }}
        >
          Retention
        </Button>
        <Button
          disabled={displayEmptyErrorRef.current || !userPermission.privDMSCanManageTemplates}
          hidden={checkedKeys.length <= 0 || (hasPreSavedForChecked && checkedKeys.length > 0)}
          type="default"
          icon={<DeleteOutlined />}
          onClick={onClickDelete}
        >
          Delete
        </Button>
        <Button
          disabled={displayEmptyErrorRef.current || !userPermission.privDMSCanManageTemplates}
          type="primary"
          icon={<PlusOutlined />}
          onClick={() => {
            AddNewParentFolder(undefined, false);
          }}
        >
          {LABEL_ADD_PARENT_LABEL}
        </Button>
      </div>
      <div className="yjFolderStructureTree ant-col ant-col-12">
        <Tree
          onClick={() => {
            onChange && onChange();
          }}
          onExpand={onExpand}
          autoExpandParent={autoExpandedParent}
          expandedKeys={expandedKeys}
          treeData={treeData}
          virtual={true}
        />
      </div>
    </div>
  );
};
